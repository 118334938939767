<template>
  <div>
    <header id="header" class="fixed-top border-bottom bg-main shadow-sm">
      <div class="container-fluid shadow-sm">
        <nav class="navbar navbar-expand-md navbar-dark px-0 pr-sm-3">
          <a
            @click="back"
            class="btn p-0 mr-md-4 text-light h5 mb-0"
          >
            <i class="fas fa-arrow-left"></i>
          </a>
          <span
            class="
              navbar-brand
              bg-transparent
              rounded
              p-0
              text-center
              mr-0 mr-md-5
            "
          >
            <span class="h6 font-weight-bold text-truncate">Produk - Edit</span>
          </span>
          <a @click="deleteData()" class="btn px-0">
            <i class="fa fa-trash text-light"></i>
          </a>
        </nav>
      </div>
      <!-- .container -->
    </header>

    <div class="container-fluid mt-header">
      <div class="row" id="body-sidemenu">
        <!-- MAIN -->
        <div id="main-content" class="col with-fixed-sidebar">
          <div class="row d-none">
            <div class="col-12">
              <div
                class="
                  alert alert-danger
                  bg-light
                  mt-3
                  mb-0
                  alert-dismissible
                  fade
                  show
                "
                role="alert"
              >
                <button type="button" class="close" data-dismiss="alert">
                  &times;
                </button>
                <strong>Alert!</strong> You should
                <a
                  href="javascript:void(0);"
                  class="alert-link"
                  title="Click to go to this message"
                  >read this message</a
                >.
              </div>
            </div>
            <!-- .col -->
          </div>
          <!-- .row -->

          <div class="row mt-4 mb-5">
            <div class="col-12">
              <Notif />
              <Form
                @submit="update"
                :validation-schema="schema"
                v-slot="{ errors }"
                method="post"
                class="mb-3 needs-validation"
              >
                <div class="row">
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label for="nama"
                        >Nama Produk: <span class="text-danger">*</span></label
                      >
                      <Field
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': errors.name }"
                        placeholder="Masukkan nama..."
                        name="name"
                        v-model="state.form.name"
                        autofocus
                      />
                      <div class="valid-feedback">Valid.</div>
                      <div class="invalid-feedback">{{ errors.name }}</div>
                    </div>
                  </div>
                  <!-- .col-* -->
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label for="status"
                        >Status:<span class="text-danger ml-1">*</span></label
                      >
                      <Field
                        name="status"
                        as="select"
                        class="form-control"
                        :class="{ 'is-invalid': errors.status }"
                        v-model="state.form.status"
                      >
                        <option value="1">Aktif</option>
                        <option value="0">Inaktif</option>
                      </Field>
                      <div class="valid-feedback">Valid.</div>
                      <div class="invalid-feedback">{{ errors.status }}</div>
                    </div>
                  </div>
                  <!-- .col-* -->
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label for="sel1"
                        >Jenis Produk:<span class="text-danger ml-1"
                          >*</span
                        ></label
                      >
                      <Field
                        name="category_id"
                        as="select"
                        class="form-control"
                        :class="{ 'is-invalid': errors.category_id }"
                        v-model="state.form.category_id"
                      >
                        <option value="">Pilih Jenis produk...</option>
                        <option v-for="dt in state.categorys" :key="dt.id" :value="dt.id">{{ dt.name }}</option>
                      </Field>
                      <div class="valid-feedback">Valid.</div>
                      <div class="invalid-feedback">{{ errors.category_id }}</div>
                    </div>
                  </div>
                  <!-- .col-* -->
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label for="modal"
                        >Harga Modal: <span class="text-danger">*</span></label
                      >
                      <currency-input
                        v-if="state.finish"
                        class="form-control currency"
                        :class="{ 'is-invalid': errors.price }"
                        v-model="state.form.price"
                        :options="{ currency: 'IDR' }"
                        name="price"
                      />
                      <Field
                        type="hidden"
                        v-model="state.form.price"
                        name="price"
                      />
                      <div class="valid-feedback">Valid.</div>
                      <div class="invalid-feedback">{{ errors.price }}</div>
                    </div>
                  </div>
                  <!-- .col-* -->
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label for="desc"
                        >Deskripsi: <span class="text-danger">*</span></label
                      >
                      <Field
                        type="hidden"
                        name="desc"
                        v-model="state.form.desc"
                      />
                      <textarea
                        class="form-control"
                        :class="{ 'is-invalid': errors.desc }"
                        rows="2"
                        name="desc"
                        placeholder="Masukkan deskripsi..."
                        v-model="state.form.desc"
                      ></textarea>
                      <div class="valid-feedback">Valid.</div>
                      <div class="invalid-feedback">{{ errors.desc }}</div>
                    </div>
                  </div>
                  <!-- .col-* -->
                  <div class="col-12 col-md-6">
                    <label for="foto"
                      >Foto Produk:<span class="text-danger ml-1"
                        >*</span
                      ></label
                    >
                    <div class="custom-file mb-3">
                      <input
                        type="file"
                        ref="fileInput"
                        class="custom-file-input form-control"
                        id="upload"
                        accept="image/*"
                        name="foto"
                        @input="pickFile"
                        @change="onFileChange"
                      />
                      <label
                        class="custom-file-label"
                        for="upload"
                        id="upload-label"
                        >Pilih gambar...</label
                      >
                      <div class="valid-feedback">Valid.</div>
                      <div class="invalid-feedback">Isian ini wajib diisi.</div>
                    </div>
                    <div class="image-area mb-4 w-50 mx-auto p-3">
                      <img
                        v-if="state.url"
                        :src="state.url"
                        alt=""
                        class="img-fluid rounded shadow-sm mx-auto d-block"
                      />
                    </div>
                  </div>
                  <!-- .col-* -->
                </div>
                <!-- .row -->

                <hr class="no-side-margin" />

                <div class="row mt-3">
                  <div class="col text-center">
                    <button type="submit" class="btn btn-main" title="Tambah" :disabled="state.loading">
                      <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" v-if="state.loading"></span>
                      <i class="fa fa-save mr-1" v-else></i>Simpan
                    </button>
                    <button
                      type="reset"
                      class="btn btn-light ml-1"
                      title="Reset isian"
                    >
                      <i class="fas fa-sync-alt mr-1"></i>Reset
                    </button>
                    <a
                      href="#"
                      class="btn btn-light ml-1"
                      @click="batal"
                      title="Batal"
                      ><i class="fas fa-times mr-1"></i>Batal</a
                    >
                  </div>
                  <!-- .col-* -->
                </div>
                <!-- .row -->
              </Form>
            </div>
            <!-- .col -->
          </div>
          <!-- .row -->
        </div>
        <!-- main-content -->
      </div>
      <!-- .row -->
    </div>
    <!-- .container -->
  </div>
</template>
<script>
import { reactive, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import axios from "axios";
import Notif from "../../../../helpers/Notif.vue";
import { useVueSweetAlert2 } from "../../../../useVueSweetAlert2.js";
import CurrencyInput from "../../../../helpers/CurrencyInput.vue";
export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const $swal = useVueSweetAlert2();
    const state = reactive({
      id: "",
      form: {
        name: "",
        status: "",
        category_id: '',
        price: 0,
        desc: "",
        photo: "",
      },
      loading: false,
      url: "",
      finish: false,
      categorys: {},
    });
    const batal = () => {
      $swal
        .fire({
          text: "Anda yakin meninggalkan form isian ini?  Perubahan tidak akan disimpan.",
          showCancelButton: true,
          confirmButtonText: "Ya",
          cancelButtonText: "Tidak",
        })
        .then((result) => {
          if (result.isConfirmed) {
            router.push({ path: "/jual/produk/info/" + state.id });
          }
        });
    };
    const loadData = async () => {
      await axios.get("/api/seller/produk/" + state.id).then((res) => {
        if (res.data.success) {
          state.form.name = res.data.data.name;
          state.form.status = res.data.data.status;
          state.form.price = Number(res.data.data.price);
          state.form.desc = res.data.data.desc;
          state.form.category_id = res.data.data.category_id;
          state.url = process.env.VUE_APP_API_URL + res.data.data.photo;
        }
        state.finish = true;
      });
    };
    const getCategory = async() => {
      await axios.get('/api/seller/produk/getCategory').then((res) => {
        if(res.data.success){
          state.categorys = res.data.data;
        }
      })
    }
    onMounted(() => {
      state.id = route.params.id;
      store.dispatch("error/setDefault");
      getCategory();
      loadData();
    });
    //start validate
    const schema = Yup.object().shape({
      name: Yup.string().required("Nama tidak boleh kosong"),
      status: Yup.string().required("Pilih Salah satu"),
      category_id: Yup.string().required("Pilih Salah satu"),
      price: Yup.string().required("Harga tidak boleh kosong"),
      desc: Yup.string().required("Deskripsi tidak boleh kosong"),
    });
    const update = () => {
      state.loading = true;
      axios.put("/api/seller/produk/" + state.id, state.form).then((res) => {
        if (res.data.success) {
          $swal.fire({
            icon: "success",
            title: "Selamat",
            text: "Produk Berhasil Dirubah!",
          });
          router.push({ path: "/jual/produk/info/" + state.id });
        }
      }, () => {
        state.loading = false;
      });
    };
    const deleteData = () => {
      if(state.id !== ''){
        $swal
        .fire({
          text: "Apakah Anda yakin Akan Menghapus Produk Ini?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: "Ya",
          cancelButtonText: "Tidak",
          confirmButtonColor: '#b50b1b',
        })
        .then((result) => {
          if (result.isConfirmed) {
            axios.delete('/api/seller/produk/'+state.id).then((res) => {
              if(res.data.success){
                $swal.fire({
                  icon: "success",
                  title: "Info",
                  text: "Produk Berhasil Di Hapus!",
                });
                router.push({ path: "/jual/produk/" });
              }else {
                $swal.fire({
                  icon: "warning",
                  title: "Info",
                  text: res.data.messages,
                });
              }
            });
          }
        });
      }
    }
    const back = () => {
      $swal.fire({
          text: "Anda yakin meninggalkan halaman ini saat proses belum selesai & data belum tersimpan?",
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak'
      }).then((result) => {
          if (result.isConfirmed) {
              router.push({path: '/jual/produk/info/'+state.id });
          }
      })
    }
    return {
      state,
      schema,
      update,
      batal,
      deleteData,
      back
    };
  },
  components: {
    Field,
    Form,
    Notif,
    CurrencyInput,
  },
  methods: {
    pickFile() {
      let input = this.$refs.fileInput;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.state.form.photo = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.$emit("input", file[0]);
      }
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.state.url = URL.createObjectURL(file);
    },
  },
  computed: {
    isPhoto() {
      return this.state.form.photo === "";
    },
  },
};
</script>